import TENANT from "./constants/tenant";
import { themeColors } from "./themeColors";
import { extendTheme } from "native-base";

interface CustomTheme {
    fontFamily?: string,
    appPalette?: any,
    languageSupported?: {
        code: string;
        language: string;
        isBase: boolean;
    }[];
    logo?: string;
    favIcon?: string;
}

export function resolveTheme(tenant: string) {
    const tenantTheme = tenantThemes.find(
        (pallete) => pallete.tenant === tenant
    );

    const theme = extendTheme<CustomTheme>({
        fontFamily: tenantTheme?.fontFamily,
        appPalette: { ...tenantTheme?.appPalette },
    });
    theme.languageSupported = tenantTheme?.languageSupported;
    theme.logo = tenantTheme?.logo;
    if (tenantTheme?.favIcon) {
      setFavicon(tenantTheme?.favIcon);
    }  
    return theme;
}

const tenantThemes = [
    {
        tenant: TENANT.HYT,
        title: "Admin",
        favIcon: "favicons/hyt.svg",
        fontFamily: "Avenir LT Pro",
        logo: "/icons/logo-header-hyt.svg",
        ...getColorConfig(TENANT.HYT),
        languageSupported: [
            {
                code: 'en-US',
                language: 'English (US)',
                isBase: false,
            },
            {
                code: 'es-CO',
                language: 'Spanish (CO)',
                isBase: true,
            },
            {
                code: 'pt-BR',
                language: 'Portugese (BR)',
                isBase: false,
            },
        ],
    },
    {
        tenant: TENANT.KARD,
        title: "Admin",
        favIcon: "favicons/1004.png",
        fontFamily: "Avenir LT Pro",
        logo: "/icons/logo-header-1004.svg",
        ...getColorConfig(TENANT.KARD),
        languageSupported: [
            {
                code: 'en-US',
                language: 'English (US)',
                isBase: true,
            },
            {
                code: 'es-CO',
                language: 'Spanish (CO)',
                isBase: false,
            },
            {
                code: 'pt-BR',
                language: 'Portugese (BR)',
                isBase: false,
            },
        ],
    },
    {
        tenant: TENANT.KANOO,
        title: "Admin",
        favIcon: "favicons/kanoo.png",
        fontFamily: "Avenir LT Pro",
        logo: "/icons/logo-header-kanoo.svg",
        ...getColorConfig(TENANT.KANOO),
        languageSupported: [
            {
                code: 'en-US',
                language: 'English (US)',
                isBase: true,
            },
            {
                code: 'es-CO',
                language: 'Spanish (CO)',
                isBase: false,
            },
            {
                code: 'pt-BR',
                language: 'Portugese (BR)',
                isBase: false,
            },
        ],
    },
    {
        tenant: TENANT.JAPAN,
        title: "Admin",
        favIcon: "favicons/1009.png",
        fontFamily: "Avenir LT Pro",
        logo: "/icons/logo-header-1009.svg",
        ...getColorConfig(TENANT.JAPAN),
        languageSupported: [
            {
                code: 'en-US',
                language: 'English (US)',
                isBase: true,
            },
            {
                code: 'es-CO',
                language: 'Spanish (CO)',
                isBase: false,
            },
            {
                code: 'pt-BR',
                language: 'Portugese (BR)',
                isBase: false,
            },
        ],
    },
];

function getColorConfig(tenant: string) {
    return themeColors.find((color) => color.tenant === tenant);
}

function setFavicon(favIconUrl: string) {
    const head = document.getElementsByTagName("head")[0];
    const favicon = document.createElement("link");
    favicon.rel = "icon";
    favicon.type = "image/x-icon";
    favicon.href = favIconUrl;
    head.appendChild(favicon);
}

  